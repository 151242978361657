<!--
  服务集成商认证（机构管理员）
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="服务集成商认证（机构管理员）" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <el-form
          ref="elForm"
          :model="form"
          :rules="rules"
          size="small"
          label-width="130px"
          label-position="left">
          <el-row type="flex" :gutter="70">
            <el-col>
              <el-form-item
                label="机构名称"
                prop="">
                <el-input maxlength="50" show-word-limit
                  style="width: 100%;"
                  readonly
                  :value="organization.name">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="从事科技服务年限"
                prop="workingYears">
                <el-input maxlength="50" show-word-limit
                  style="width: 100%;"
                  v-model.number="form.workingYears"
                  placeholder="请输入从事科技服务年限">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="70">
            <el-col>
              <el-form-item
                label="是否为技术转移示范机构"
                prop="technologyTransfer">
                <el-select
                  style="width: 100%;"
                  v-model="form.technologyTransfer"
                  placeholder="请选择是否为技术转移示范机构">
                  <el-option value="是"></el-option>
                  <el-option value="否"></el-option>
                </el-select>
                <el-radio-group
                  style="margin-top: 10px;"
                  v-if="form.technologyTransfer === '是'"
                  v-model="form.technologyTransferStr">
                  <el-radio label="国家级"></el-radio>
                  <el-radio label="省级"></el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="是否有专业技术转移服务平台"
                prop="technologyTransferPlatform">
                <el-select
                  style="width: 100%;"
                  v-model="form.technologyTransferPlatform"
                  placeholder="请选择是否有专业技术转移服务平台">
                  <el-option value="是"></el-option>
                  <el-option value="否"></el-option>
                </el-select>
                <el-input maxlength="50" show-word-limit
                  v-if="form.technologyTransferPlatform === '是'"
                  style="width: 100%;margin-top:10px;"
                  v-model="form.technologyTransferPlatformStr"
                  placeholder="请输入平台名称">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="70">
            <el-col>
              <el-form-item
                label="人员情况">
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="总人数"
                    prop="personnelSituation.zrs">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.personnelSituation.zrs"
                      placeholder="请输入总人数">
                    </el-input>
                  </el-form-item>
                </el-row>
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="专职从事技术转移人员"
                    prop="personnelSituation.zyry">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.personnelSituation.zyry"
                      placeholder="请输入专职从事技术转移人员">
                    </el-input>
                  </el-form-item>
                </el-row>
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="硕士(含以上)"
                    prop="personnelSituation.sh">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.personnelSituation.sh"
                      placeholder="请输入硕士(含以上)人员数量">
                    </el-input>
                  </el-form-item>
                </el-row>
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="大学本科"
                    prop="personnelSituation.bk">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.personnelSituation.bk"
                      placeholder="请输入大学本科人员数量">
                    </el-input>
                  </el-form-item>
                </el-row>
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="大专(及以下)"
                    prop="personnelSituation.dz">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.personnelSituation.dz"
                      placeholder="请输入大专(及以下)人员数量">
                    </el-input>
                  </el-form-item>
                </el-row>
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="高级职称"
                    prop="personnelSituation.gjzc">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.personnelSituation.gjzc"
                      placeholder="请输入高级职称人员数量">
                    </el-input>
                  </el-form-item>
                </el-row>
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="中级职称"
                    prop="personnelSituation.zjzc">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.personnelSituation.zjzc"
                      placeholder="请输入中级职称人员数量">
                    </el-input>
                  </el-form-item>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="机构技术经纪人数量">
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="高级"
                    prop="brokersNumber.gj">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.brokersNumber.gj"
                      placeholder="请输入高级人数">
                    </el-input>
                  </el-form-item>
                </el-row>
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="中级"
                    prop="brokersNumber.zj">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.brokersNumber.zj"
                      placeholder="请输入中级人数">
                    </el-input>
                  </el-form-item>
                </el-row>
                <el-row type="flex">
                  <el-form-item
                    label-width="185px"
                    label="初级"
                    prop="brokersNumber.cj">
                    <el-input maxlength="50" show-word-limit
                      v-model.number="form.brokersNumber.cj"
                      placeholder="请输入中级人数">
                    </el-input>
                  </el-form-item>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="70">
            <el-col>
              <el-form-item
                label="是否有科技服务章程"
                prop="serviceCharter">
                <el-radio-group v-model="form.serviceCharter">
                  <el-radio label="是"></el-radio>
                  <el-radio label="否"></el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                label="是否有客户管理服务规范"
                prop="customerManagementStandard">
                <el-radio-group v-model="form.customerManagementStandard">
                  <el-radio label="是"></el-radio>
                  <el-radio label="否"></el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="是否开展国际技术转移合作"
            prop="technologyTransferCooperation">
            <el-radio-group v-model="form.technologyTransferCooperation">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="服务对象群体"
            prop="targetGroups">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="请填写明确的服务对象群体"
              v-model="form.targetGroups">
            </el-input>
          </el-form-item>
          <el-form-item
            label="经济社会效益"
            prop="socialResults">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="介绍机构近两年来在技术服务方面所取得的业绩，比如组织大型技术交易活动、技术推广和培训等情况；以支撑中小企业创新、地方或行业经济发展与技术进步所做的贡献，对技术服务行业的示范带动作用。"
              v-model="form.socialResults">
            </el-input>
          </el-form-item>
          <el-form-item
            label="社会信誉"
            prop="socialReputation">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="介绍机构的社会信誉情况，例如近两年获得过哪些媒体的报道或被树立典型而在行业内进行宣传、投诉和诉讼情况等。"
              v-model="form.socialReputation">
            </el-input>
          </el-form-item>
          <el-form-item
            label="典型服务案例"
            prop="serviceCase">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="请填写至少3个以上典型服务案例，包括客户名称、服务内容、服务方式、经济社会效益等。"
              v-model="form.serviceCase">
            </el-input>
          </el-form-item>
          <el-form-item
            label="经营理念"
            prop="managementIdea">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="请填写业务定位及发展目标、服务战略性新兴产业上的经营特色等"
              v-model="form.managementIdea">
            </el-input>
          </el-form-item>
          <el-form-item
            label="经营条件"
            prop="operatingConditions">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="包括经营场所、经营手段、经费来源、合作伙伴及客户群等情况。"
              v-model="form.operatingConditions">
            </el-input>
          </el-form-item>
          <el-form-item
            label="上年度技术服务情况"
            prop="lastYearTechnicalServices">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="从事科技服务业以来服务企业数量，承办活动数量，促进科技成果转化项数，促成的技术交易额，促成企业融资金融等。"
              v-model="form.lastYearTechnicalServices">
            </el-input>
          </el-form-item>
          <el-form-item
            label="上年度财务收入情况"
            prop="lastYearFinancialIncome">
            <el-input
              style="width: 100%;"
              type="textarea" maxlength="300" show-word-limit
              :rows="4"
              placeholder="收入总计（）万元，技术服务收入（）万元，技术性收入占总收入的比例（）％"
              v-model="form.lastYearFinancialIncome">
            </el-input>
          </el-form-item>
          <el-form-item
            label="附件"
            prop="attachmentDocument">
            <upload-files
              :maxSize="4"
              accept=""
              tips="表单填报内容的一些证明材料"
              :fileList.sync="form.attachmentDocument">
            </upload-files>
          </el-form-item>
        </el-form>

        <div class="btn_box tw-mt-30px">
          <el-button
            style="margin-right: 20px;"
            type="primary"
            size="small"
            @click="submitForm"
            :loading="btnLoading"
            :disabled="!agree">
            提交申请
          </el-button>
          <el-checkbox v-model="agree">
            <p class="tw-text-14px tw-text-primary-text">已仔细阅读并同意
              <a :href="`${publicPath}诚信承诺书.pdf`" target="_blank">《诚信承诺书》</a>
            </p>
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    const technologyTransferStr = (rule, value, callback) => {
      if (value === '是' && this.form.technologyTransferStr === '') {
        callback(new Error('请选择具体级别'))
      } else {
        callback()
      }
    }
    const technologyTransferPlatformStr = (rule, value, callback) => {
      if (value === '是' && this.form.technologyTransferPlatformStr === '') {
        callback(new Error('请输入平台名称'))
      } else {
        callback()
      }
    }
    return {
      // 页面loading
      loading: false,
      btnLoading: false,
      agree: false, // 是否同意条款
      /* form: {
        workingYears: 100,
        technologyTransfer: '是',
        technologyTransferStr: '国家级',
        technologyTransferPlatform: '否',
        technologyTransferPlatformStr: '',
        personnelSituation: {
          zrs: 3520,
          zyry: 2000,
          sh: 300,
          bk: 100,
          dz: 3120,
          gjzc: 2000,
          zjzc: 1000
        },
        brokersNumber: {
          gj: 30,
          zj: 40,
          cj: 1000
        },
        serviceCharter: '是',
        customerManagementStandard: '是',
        technologyTransferCooperation: '是',
        targetGroups: '1、皇帝\n2、朱晨\n3、金波',
        socialResults: '1、给中国增加了30%GDP增长',
        socialReputation: '百年根据，千年传承',
        serviceCase: '1、给龙王呼过雨\n2、为韩立铸过剑\n3、帮朱晨打过代码',
        managementIdea: '无法无天，无欲无求',
        operatingConditions: '很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱很多钱\n很多钱很多钱',
        lastYearTechnicalServices: '1、开发出来了不可能性引擎\n2、修好了方尖碑',
        lastYearFinancialIncome: '1、大约赚了100个地球的金子',
        attachmentDocument: [
          {
            uid: 1615542149805,
            name: 'certificate.png',
            id: 'd1d0d1bf94511ba85daeb2d4b3aa45d9',
            createdAt: '2021-03-12 17:42:32',
            updatedAt: '2021-03-12 17:42:32',
            deletedAt: null,
            driver: 'local',
            originName: 'certificate.png',
            extName: 'png',
            contentType: 'image/png',
            size: '1509',
            path: '2021/3/12/d1d0d1bf94511ba85daeb2d4b3aa45d9.png',
            url: 'http://192.168.1.175:9919/media/d1d0d1bf94511ba85daeb2d4b3aa45d9',
            downloadUrl: 'http://192.168.1.175:9919/media/d1d0d1bf94511ba85daeb2d4b3aa45d9/download',
            status: 'success'
          },
          {
            uid: 1615542174224,
            name: '测试doc-word.doc',
            id: 'ea90dec00d6a6405c0848fa994cc388d',
            createdAt: '2021-03-12 17:42:56',
            updatedAt: '2021-03-12 17:42:56',
            deletedAt: null,
            driver: 'local',
            originName: '测试doc-word.doc',
            extName: 'doc',
            contentType: 'application/msword',
            size: '747',
            path: '2021/3/12/ea90dec00d6a6405c0848fa994cc388d.doc',
            url: 'http://192.168.1.175:9919/media/ea90dec00d6a6405c0848fa994cc388d',
            downloadUrl: 'http://192.168.1.175:9919/media/ea90dec00d6a6405c0848fa994cc388d/download',
            status: 'success'
          }
        ]
      }, */
      form: {
        workingYears: '', // 从事科技服务年限
        technologyTransfer: '', // 是否为技术转移示范机构
        technologyTransferStr: '', // 是否为技术转移示范机构 选择是的话需要选择[国家级或者省级]
        technologyTransferPlatform: '', // 是否有专业技术转移服务平台
        technologyTransferPlatformStr: '', // 是否有专业技术转移服务平台 选择是 则填写
        personnelSituation: { // 人员情况
          zrs: 0, // 总人数
          zyry: 0, // 专职从事技术转移人员
          sh: 0, // 硕士(含以上)
          bk: 0, // 大学本科
          dz: 0, // 大专及以下
          gjzc: 0, // 高级职称
          zjzc: 0 // 中级职称
        },
        brokersNumber: { // 机构技术经纪人数量
          gj: 0, // 高级人数
          zj: 0, // 中级人数
          cj: 0 // 初级人数
        },
        serviceCharter: '', // 是否有科技服务章程
        customerManagementStandard: '', // 是否有客户管理服务规范
        technologyTransferCooperation: '', // 是否开展国际技术转移合作
        targetGroups: '', // 服务对象群体
        socialResults: '', // 经济社会效益
        socialReputation: '', // 社会信誉
        serviceCase: '', // 典型服务案例
        managementIdea: '', // 经营理念
        operatingConditions: '', // 经营条件
        lastYearTechnicalServices: '', // 上年度技术服务情况
        lastYearFinancialIncome: '', // 上年度财务收入情况
        attachmentDocument: [] // 附件
      },
      rules: {
        workingYears: [
          { required: true, message: '请输入从事科技服务年限', trigger: 'blur' },
          { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
        ],
        technologyTransfer: [
          { required: true, message: '请选择是否为技术转移示范机构', trigger: 'change' },
          { validator: technologyTransferStr, trigger: 'change' }
        ],
        technologyTransferPlatform: [
          { required: true, message: '请选择是否有专业技术转移服务平台', trigger: 'change' },
          { validator: technologyTransferPlatformStr, trigger: 'blur' }
        ],
        personnelSituation: {
          zrs: [
            { required: true, message: '请输入总人数', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          zyry: [
            { required: true, message: '请输入专职从事技术转移人员数量', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          sh: [
            { required: true, message: '请输入硕士(含以上)人员数量', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          bk: [
            { required: true, message: '请输入大学本科人员数量', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          dz: [
            { required: true, message: '请输入大专(及以下)人员数量', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          gjzc: [
            { required: true, message: '请输入高级职称人员数量', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          zjzc: [
            { required: true, message: '请输入中级职称人员数量', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ]
        },
        brokersNumber: {
          gj: [
            { required: true, message: '请输入高级人员数量', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          zj: [
            { required: true, message: '请输入中级人员数量', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          cj: [
            { required: true, message: '请输入初级人员数量', trigger: 'blur' },
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ]
        },
        serviceCharter: [
          { required: true, message: '请选择是否有科技服务章程', trigger: 'change' }
        ],
        customerManagementStandard: [
          { required: true, message: '是否有客户管理服务规范', trigger: 'change' }
        ],
        technologyTransferCooperation: [
          { required: true, message: '请选择是否开展国际技术转移合作', trigger: 'change' }
        ],
        targetGroups: [
          { required: true, message: '请输入服务对象群体', trigger: 'blur' }
        ],
        socialResults: [
          { required: true, message: '请输入经济社会效益', trigger: 'blur' }
        ],
        socialReputation: [
          { required: true, message: '请输入经济社会效益', trigger: 'blur' }
        ],
        serviceCase: [
          { required: true, message: '请输入典型服务案例', trigger: 'blur' }
        ],
        managementIdea: [
          { required: true, message: '请输入经营理念', trigger: 'blur' }
        ],
        operatingConditions: [
          { required: true, message: '请输入经营条件', trigger: 'blur' }
        ],
        lastYearTechnicalServices: [
          { required: true, message: '请输入上年度技术服务情况', trigger: 'blur' }
        ],
        lastYearFinancialIncome: [
          { required: true, message: '请输入上年度财务收入情况', trigger: 'blur' }
        ],
        attachmentDocument: [
          { required: true, message: '请上传附件', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    // 机构信息
    organization () {
      return this.$store.state.userInfo.organization
    },
    // 开放目录
    publicPath () {
      return process.env.BASE_URL
    }
  },
  methods: {
    // 表单验证
    submitForm () {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          // 申请服务机构
          this.orgAdminAggregatorRegister()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    orgAdminAggregatorRegister () {
      const {
        technologyTransfer,
        technologyTransferStr,
        technologyTransferPlatform,
        technologyTransferPlatformStr,
        personnelSituation,
        brokersNumber,
        attachmentDocument
      } = this.form

      const formData = {
        ...this.form,
        technologyTransfer: technologyTransfer === '是' ? technologyTransferStr : technologyTransfer,
        technologyTransferPlatform: technologyTransferPlatform === '是' ? technologyTransferPlatformStr : technologyTransferPlatform,
        personnelSituation: Object.values(personnelSituation).join(','),
        brokersNumber: Object.values(brokersNumber).join(','),
        attachmentDocument: attachmentDocument ? JSON.stringify(attachmentDocument) : ''
      }

      this.btnLoading = true
      api.orgAdminAggregatorRegister({
        ...formData
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('申请成功')
          this.$router.replace('/personal-info')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0px;
    .main_content {
      width: 1100px;
      margin: 0 auto;
      .module_box {
        .module_box_header {
          display: flex;
          align-items: center;
          height: 40px;
          margin-bottom: 20px;
          &::before {
            content: "";
            width: 3px;
            height: 20px;
            background: #3473E6;
            border-radius: 1px;
            margin-right: 10px;
          }
          & > p {
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: #31394C;
          }
        }
        .module_box_main {
          padding: 0 50px 0 20px;
        }
        &+.module_box {
          margin-top: 40px;
        }
      }
      .btn_box {
        display: flex;
        align-items: center;
        padding-left: 130px;

      }
      .logourl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader-icon {
          width: 120px;
          height: 120px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & i {
            font-size: 28px;
            color: #8c939d;
          }
        }
      }
      .codeurl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader_box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 220px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & > i {
            font-size: 40px;
            color: #9AA0A9;
          }
          & > p {
            width: 200px;
            font-size: 14px;
            line-height: 20px;
            color: #909399;
            margin-top: 15px;
          }
        }
      }
      .checkbox_wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 450px;
      }
    }
  }
}
</style>
